import React from "react"

import { classNames } from "../../utils/class-names"
import SectionTitleContainer from "../section-title-container"
import { SECTION_CLASSES } from "../section-v2/section-classes"
import BackgroundRectangle, {
  Props as BackgroundRectangleProps,
} from "../side-background-rectangle"
import * as styles from "./index.module.scss"

type Props = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  childrenBelowSubtitle?: React.ReactNode
  children: React.ReactNode
  overflowHidden?: boolean
  noTopPadding?: boolean
  noBottomPadding?: boolean
  rectangleSide?:
    | Exclude<BackgroundRectangleProps["position"], "fullWidth">
    | "noRectangle"
  variant?: "light" | "dark" | "tan"
  titleCustomWidth?: boolean
  centeredTitle?: boolean
}

function SectionLayout({
  title,
  subtitle,
  rectangleSide = "noRectangle",
  children,
  childrenBelowSubtitle,
  variant = "light",
  noTopPadding,
  overflowHidden,
  noBottomPadding,
  titleCustomWidth,
  centeredTitle,
}: Props) {
  return (
    <section
      className={classNames(
        styles[rectangleSide],
        styles.container,
        styles[variant],
        noBottomPadding && styles.noBottomPadding,
        noTopPadding && styles.noTopPadding,
        overflowHidden && styles.overflowHidden,
        SECTION_CLASSES[variant],
      )}
    >
      <div className={classNames(styles.innerContainer, styles[rectangleSide])}>
        {rectangleSide !== "noRectangle" && (
          <BackgroundRectangle
            position={rectangleSide}
            color={variant === "dark" ? "darkGray" : "tan"}
            zIndex={0}
          />
        )}
        <div
          className={classNames(
            styles.textContainer,
            titleCustomWidth && styles.textContainerCustomWidth,
            centeredTitle && styles.centeredTitle,
          )}
        >
          <SectionTitleContainer title={title} subtitle={subtitle}>
            {childrenBelowSubtitle}
          </SectionTitleContainer>
        </div>
        <div className={styles.childrenContainer}>{children}</div>
      </div>
    </section>
  )
}

export default SectionLayout
