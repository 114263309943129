// extracted by mini-css-extract-plugin
export var centeredTitle = "cY_jb";
export var childrenContainer = "cY_jd";
export var container = "cY_f";
export var dark = "cY_cS";
export var innerContainer = "cY_ck";
export var left = "cY_cz";
export var light = "cY_cR";
export var noBottomPadding = "cY_h6";
export var noRectangle = "cY_h9";
export var noTopPadding = "cY_h7";
export var overflowHidden = "cY_h8";
export var right = "cY_cB";
export var subtitle = "cY_k";
export var tan = "cY_cw";
export var textContainer = "cY_b3";
export var textContainerCustomWidth = "cY_jc";