import * as styles from "./index.module.scss"

export const SECTION_CLASSES = {
  /** Transparent */
  light: styles.light,
  tan: styles.tan,
  lightToDark: styles.lightToDark,
  /** Light gray */
  highlightLight: styles.highlightLight,
  dark: styles.dark,
  footnote: styles.footnote,
}
