// extracted by mini-css-extract-plugin
export var border1 = "cS_hP";
export var border2 = "cS_hQ";
export var border3 = "cS_hR";
export var border4 = "cS_hS";
export var container = "cS_f";
export var dark = "cS_cS";
export var large = "cS_bT";
export var ldsRing = "cS_hT";
export var light = "cS_cR";
export var medium = "cS_bV";
export var primaryPressed = "cS_hV";
export var xlarge = "cS_hN";