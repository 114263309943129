// extracted by mini-css-extract-plugin
export var button = "J_v";
export var children = "J_bY";
export var disabled = "J_bQ";
export var fullWidth = "J_bX";
export var iconWrapper = "J_b0";
export var large = "J_bT";
export var link = "J_bH";
export var loading = "J_bP";
export var loadingContainer = "J_bZ";
export var medium = "J_bV";
export var primary = "J_bN";
export var secondary = "J_bR";
export var small = "J_bW";
export var tertiary = "J_bS";